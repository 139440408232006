import { Link } from "react-router-dom";

const PageBanner = ({ pageName }) => {
  return (
    <section
      className="page-banner-area overlay pt-220 rpt-150 pb-170 rpb-100 rel z-1 bgs-cover text-center"
      /*style={{ backgroundImage: "url(assets/images/banner/banner-bg.jpg)" }} */
      style={{ backgroundImage: "url(assets/images/hero/hero-two-bg.png)", backgroundSize: 'inherit' }}  
    >
      <div className="container">
        <div className="banner-inner rpt-10">
          <h2 className="page-title wow fadeInUp delay-0-2s">{pageName}</h2>
          {/* <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
              <li className="breadcrumb-item">
                <Link to="/">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">{pageName}</li>
            </ol>
          </nav> */}
        </div>
      </div>
    </section>
  );
};

export default PageBanner;