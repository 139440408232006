import { Link } from "react-router-dom";

const DefaultFooter = ({ dark }) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <footer
      className="main-footer rel z-1"
      style={{
        backgroundImage: "url(assets/images/footer/footer-bg-shape.png)",
      }}
    >
      <div className="container container-1290">
        <div className="footer-top pt-80 pb-60">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="footer-logo mb-20 wow fadeInRight delay-0-2s animated">
                <Link to="/">
                  <a>
                    <img
                      src={
                        dark
                          ? "assets/images/logos/footer-logo-white.png"
                          : "assets/images/logos/footer-logo.png"
                      }
                      alt="Logo"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-lg-8 text-lg-end">
              <div className="social-style-four mb-20 wow fadeInLeft delay-0-2s animated">
                <a href="#">
                  <i className="fab fa-linkedin" /> <span>Linkedin</span>
                </a>
                {/* <a href="#">
                  <i className="fab fa-twitter" /> <span>Twitter</span>
                </a>
                <a href="#">
                  <i className="fab fa-dribbble" /> <span>Dribbble</span>
                </a>
                <a href="#">
                  <i className="fab fa-behance" /> <span>Behance</span>
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="footer-left-content pt-80">
              <div className="lets-work mb-50 wow fadeInUp delay-0-2s animated">
                {/* <img
                  src="assets/images/footer/lets-work.png"
                  alt="Let's Work Man"
                /> */}
                <span>Let’s Work Together</span>
              </div>
              <div className="footer-contact-info wow fadeInUp delay-0-3s animated">
                <a
                  className="theme-btn style-three"
                  href="mailto:daxonixinfotech@gmail.com"
                >
                  daxonixinfotech@gmail.com<i className="far fa-arrow-right" />
                </a>
                <a
                  className="theme-btn style-three phone-number"
                  href="callto:+000(123)45688"
                >
                  +91 9879439697 <i className="far fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="footer-right-content">
              <h4 className="footer-title wow fadeInUp delay-0-2s animated">
                Quick Links
              </h4>
              <div className="footer-widget widget_nav_menu">
                <ul className="list-style-two wow fadeInUp delay-0-3s animated">
                  <li onClick={() => scrollTop()}>
                    <Link to="/">
                      Home
                    </Link>
                  </li>
                  <li onClick={() => scrollTop()}>
                    <Link to="/services">
                      Popular Services
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/team">
                      Team Member
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/blog">
                      Latest News
                    </Link>
                  </li> */}
                  <li onClick={() => scrollTop()}>
                    <Link to="/contact">
                      Contact Us?
                    </Link>
                  </li>
                </ul>
                <ul className="list-style-two wow fadeInUp delay-0-4s animated">
                  {/* <li>
                    <Link to="/index">
                      UI/UX Design
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/index2">
                      Development
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/index3">
                      Marketing
                    </Link>
                  </li> */}
                  <li onClick={() => scrollTop()}>
                    <Link to="/contact">
                      Need a Career
                    </Link>
                  </li>
                  <li onClick={() => scrollTop()}>
                    <Link to="/faqs">
                      Faqs ?
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row align-items-center">
            {/* <div className="col-xl-8 col-lg-6">
              <div className="footer-bottom-menu pt-40 pb-35 rpb-0 wow fadeInRight delay-0-2s animated">
                <ul>
                  <li>
                    <Link to="/about">
                      <a>About</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/faqs">
                      <a>FAQs</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      How IT Works
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-xl-4 col-lg-6">
              <div className="copyright-text text-lg-end pt-40 pb-35 rpt-10 wow fadeInLeft delay-0-2s animated">
                <p style={{fontSize:15}}>
                  Copyright @2024,{" "}
                  <Link to="/">
                    <a>Daxonix Infotech</a>
                  </Link>{" "}
                  All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default DefaultFooter;
