import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

const DaskTopMenu = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <ul className="navigation d-none d-lg-flex desktop-menu">
      <li>
        <Link to="/about" onClick={handleScrollToTop}>
          About Us
        </Link>
      </li>
      {/* <li>
        <Link to="/pricing">
          Pricing Plan
        </Link>
      </li> */}
      <li>
        <Link to="/faqs" onClick={handleScrollToTop}>
          faqs
        </Link>
      </li>
      <li>
        <Link to="/services" onClick={handleScrollToTop}>
          Our Services
        </Link>
      </li>
      <li>
        <Link to="/project-list" onClick={handleScrollToTop}>
          <a to="project-list">Our Work</a>
        </Link>
      </li>
    </ul>
  );
};

const MobileMenu = ({closeMenu}) => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <ul className="navigation d-block d-lg-none mobile-menu">
      <li onClick={closeMenu}>
        <Link to="/about" onClick={handleScrollToTop}>
          About Us
        </Link>
      </li>
      {/* <li onClick={closeMenu}>
        <Link to="/pricing">
          Pricing Plan
        </Link>
      </li> */}
      <li onClick={closeMenu} >
        <Link to="/faqs" onClick={handleScrollToTop}>
          faqs
        </Link>
      </li>
      <li onClick={closeMenu} >
        <Link to="/services" onClick={handleScrollToTop}>
          Our Services
        </Link>
      </li>
      <li onClick={closeMenu}>
        <Link to="/project-list" onClick={handleScrollToTop}>
        Our Work
        </Link>
      </li>
    </ul>
  );
};

const Menu = () => {
  return (
    <Fragment>
          <DaskTopMenu />
          <MobileMenu />
    </Fragment>
  );
};

export default Menu;