// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCtzeeMiCV1WB3u72RzMDehNw3e-1ObL7I",
  authDomain: "daxonixinfotech-c94ec.firebaseapp.com",
  projectId: "daxonixinfotech-c94ec",
  storageBucket: "daxonixinfotech-c94ec.firebasestorage.app",
  messagingSenderId: "237383360961",
  appId: "1:237383360961:web:2871cfb3e7c212c7974f40",
  measurementId: "G-FQ1K5WPRM3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);