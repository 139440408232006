import { Link } from "react-router-dom";
import PageBanner from "../components/PageBanner";
import Layout from "../layouts/Layout";

const ProjectList = () => {
  return (
    <Layout header={2} footer={2}>
      {/* Page Banner Start */}
      {/* <PageBanner pageName={"Delivering Innovative IT Solutions for a Digital Future"} /> */}
      <section
        className="page-banner pt-210 rpt-150 rpb-15 rel z-1"
        style={{ backgroundImage: "url(assets/images/hero/hero-two-bg.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="hero-title mb-10 rmb-50 wow fadeInUp delay-0-2s">Our Success Stories</h1>
              <p className="work-para1">Real-world solutions that transformed businesses, showcasing our expertise in delivering impactful IT solutions across diverse industries.</p>
            </div>
            <div className="col-lg-4">
              <div className="work-image-part rmb-55 wow fadeInLeft delay-0-2s">
                <img src="assets/images/projects/our-work-2.png" alt="Our Work" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Page Banner End */}
      {/* Project List Area start */}
      <section className="project-list-area pb-10 rpb-25">
        <div className="container">
          <div className="project-item style-two">
            <div className=" wow fadeInLeft delay-0-2s">
              <img
                src="assets/images/projects/project-list1.jpg"
                alt="Project List"
              />
              {/* <Link to="/project-details">
                <a className="project-btn">
                  <i className="far fa-arrow-right" />
                </a>
              </Link> */}
            </div>
            <div className="content wow fadeInRight delay-0-2s">
              <Link to="/projects">
                <a className="category">Creative Work</a>
              </Link>
              <h2>
                <Link to="/project-details">
                  <a>Coral: Relationship Coach</a>
                </Link>
              </h2>
              <a>Android, iOS</a>
              <p>
                Real couples need help sustaining real intimacy. Join the
                hundreds of thousands of people using Coral to maintain fun,
                healthy and deeply connected relationships.
              </p>
              <a
                href="https://apps.apple.com/in/app/coral-relationship-coach/id1448861466"
                target="_blank"
              >
                <img
                  src="assets/images/appStore.png"
                  alt="Project List"
                  style={{ width: 100 }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=app.getcoral.m"
                target="_blank"
              >
                <img
                  src="assets/images/google.png"
                  alt="Project List"
                  style={{ width: 100, marginLeft: "5%" }}
                />
              </a>
              {/* <Link to="/project-details">
                <a className="read-more">
                  Project Details <i className="far fa-arrow-right" />
                </a>
              </Link> */}
            </div>
          </div>
          <div className="project-item style-two">
            <div className="content wow fadeInLeft delay-0-2s">
              <Link to="/projects">
                <a className="category">Crypto Product</a>
              </Link>
              <h2>
                <Link to="/project-details">
                  <a>Yield Pro Simulator</a>
                </Link>
              </h2>
              <a>Android, iOS, WebSite</a>
              <hr />
              <p>
                Try Yield Pro risk-free with our easy-to-use educational
                app!Test your market views, build investment strategies and
                explore our offering through realistic simulations
              </p>
              <a
                href="https://apps.apple.com/us/app/yield-pro-simulator/id6445887027"
                target="_blank"
              >
                <img
                  src="assets/images/appStore.png"
                  alt="Project List"
                  style={{ width: 100 }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.yield.demosp"
                target="_blank"
              >
                <img
                  src="assets/images/google.png"
                  alt="Project List"
                  style={{ width: 100, marginLeft: "5%" }}
                />
              </a>
              <a
                href="https://yield.app/yield-pro"
                target="_blank"
                style={{ marginLeft: "5%", color: "#512da8", fontSize: 15 }}
              >
                WebSite
              </a>
              {/* <Link to="/project-details">
                <a className="read-more">
                  Project Details <i className="far fa-arrow-right" />
                </a>
              </Link> */}
            </div>
            <div className=" wow fadeInRight delay-0-2s">
              <img
                src="assets/images/projects/project-list2.jpg"
                alt="Project List"
              />
              {/* <Link to="/project-details">
                <a className="project-btn">
                  <i className="far fa-arrow-right" />
                </a>
              </Link> */}
            </div>
          </div>
          <div className="project-item style-two">
            <div className=" wow fadeInLeft delay-0-2s">
              <img
                src="assets/images/projects/project-list3.jpg"
                alt="Project List"
              />
              {/* <Link to="/project-details">
                <a className="project-btn">
                  <i className="far fa-arrow-right" />
                </a>
              </Link> */}
            </div>
            <div className="content wow fadeInRight delay-0-2s">
              <Link to="/projects">
                <a className="category">Creative Work</a>
              </Link>
              <h2>
                <Link to="/project-details">
                  <a>Inner Room</a>
                </Link>
              </h2>
              <a>Android, iOS</a>
              <hr />
              <p>
                Inner Room is a creative, free prayer list app from 24-7 Prayer,
                that helps you turn your biggest distraction into a tool for
                prayer.Jesus said, ‘But you, when you pray, go into your inner
                room, close your door and pray to your Father...’ Matthew 6:6
                (NASB)
              </p>
              <a
                href="https://apps.apple.com/us/app/inner-room/id1437144946"
                target="_blank"
              >
                <img
                  src="assets/images/appStore.png"
                  alt="Project List"
                  style={{ width: 100 }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.innerroomapp"
                target="_blank"
              >
                <img
                  src="assets/images/google.png"
                  alt="Project List"
                  style={{ width: 100, marginLeft: "5%" }}
                />
              </a>
              {/* <Link to="/project-details">
                <a className="read-more">
                  Project Details <i className="far fa-arrow-right" />
                </a>
              </Link> */}
            </div>
          </div>
          <div className="project-item style-two">
            <div className="content wow fadeInLeft delay-0-2s">
              <Link to="/projects">
                <a className="category">Custom Product</a>
              </Link>
              <h2>
                <Link to="/project-details">
                  <a>Almost Everything: Audiobook</a>
                </Link>
              </h2>
              <a>Android, iOS</a>
              <hr />
              <p>
                Using almost everything app, you can listen Tamil book summaries
                in audio format.
              </p>
              <a
                href="https://apps.apple.com/us/app/almost-everything-audiobooks/id1609912267"
                target="_blank"
              >
                <img
                  src="assets/images/appStore.png"
                  alt="Project List"
                  style={{ width: 100 }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.almosteverything.booksummaries.podcasts.audiobooksapp&hl=en_IN&gl=US"
                target="_blank"
              >
                <img
                  src="assets/images/google.png"
                  alt="Project List"
                  style={{ width: 100, marginLeft: "5%" }}
                />
              </a>
              {/* <Link to="/project-details">
                <a className="read-more">
                  Project Details <i className="far fa-arrow-right" />
                </a>
              </Link> */}
            </div>
            <div className=" wow fadeInRight delay-0-2s">
              <img
                src="assets/images/projects/project-list4.jpg"
                alt="Project List"
              />
              {/* <Link to="/project-details">
                <a className="project-btn">
                  <i className="far fa-arrow-right" />
                </a>
              </Link> */}
            </div>
          </div>
          <div className="project-item style-two">
            <div className="wow fadeInLeft delay-0-2s">
              <img
                src="assets/images/projects/project-list5.jpg"
                alt="Project List"
              />
              {/* <Link to="/project-details">
                <a className="project-btn">
                  <i className="far fa-arrow-right" />
                </a>
              </Link> */}
            </div>
            <div className="content wow fadeInRight delay-0-2s">
              <Link to="/projects">
                <a className="category">Dating Product</a>
              </Link>
              <h2>
                <Link to="/project-details">
                  <a>Kupenda: Dating App & puzzles</a>
                </Link>
              </h2>
              <hr />
              <p>
                Kupenda is designed for people who value respect. From opening
                doors and letting you order first to walking you to your car or
                front door and being protective as we walk by your side, these
                are simple gestures and symbols of deep respect. We believe
                courtship is not superficial; it reflects our values and the
                foundation of respectable standards in dating.
              </p>
              {/* <Link to="/project-details">
                <a className="read-more">
                  Project Details <i className="far fa-arrow-right" />
                </a>
              </Link> */}
              <a
                href="https://apps.apple.com/us/app/almost-everything-audiobooks/id1609912267"
                target="_blank"
              >
                <img
                  src="assets/images/appStore.png"
                  alt="Project List"
                  style={{ width: 100 }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.almosteverything.booksummaries.podcasts.audiobooksapp&hl=en_IN&gl=US"
                target="_blank"
              >
                <img
                  src="assets/images/google.png"
                  alt="Project List"
                  style={{ width: 100, marginLeft: "5%" }}
                />
              </a>
            </div>
          </div>
          <div className="project-item style-two">
            <div className="content wow fadeInLeft delay-0-2s">
              <Link to="/projects">
                <a className="category">Custom Product</a>
              </Link>
              <h2>
                <Link to="/project-details">
                  <a>Dubai Municipality</a>
                </Link>
              </h2>
              <hr />
              <p>
                Dubai Municipality’s newly revamped mobile app, provides a range
                of smart services to match the new digital era, complemented
                with a fresh innovative vibrant look, intuitive navigation,
                voice enabled smart search and quick service. This Mobile App is
                the smart gateway to many Dubai Municipality digital services
                and information about the city.
              </p>
              <a
                href="https://apps.apple.com/in/app/dubai-municipality/id1504636184"
                target="_blank"
              >
                <img
                  src="assets/images/appStore.png"
                  alt="Project List"
                  style={{ width: 100 }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=ae.gov.dm.uma"
                target="_blank"
              >
                <img
                  src="assets/images/google.png"
                  alt="Project List"
                  style={{ width: 100, marginLeft: "5%" }}
                />
              </a>
              {/* <Link to="/project-details">
                <a className="read-more">
                  Project Details <i className="far fa-arrow-right" />
                </a>
              </Link> */}
            </div>
            <div className=" wow fadeInRight delay-0-2s">
              <img
                src="assets/images/projects/project-list6.jpg"
                alt="Project List"
              />
              {/* <Link to="/project-details">
                <a className="project-btn">
                  <i className="far fa-arrow-right" />
                </a>
              </Link> */}
            </div>
          </div>
        </div>
      </section>
      {/* Project List Area end */}
      {/* Work With Area start */}
      <section className="work-with-area pb-150 rpb-145 rel z-1">
        <div className="container">
          <div className="row justify-content-center pb-45 rpb-25">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center wow fadeInUp delay-0-2s">
                <span className="sub-title mb-15">Work With Us</span>
                <h2>Have Any Projects On Minds! Feel Free to Contact Us</h2>
                <Link to="/contact">
                  <a className="explore-more text-start mt-30">
                    <i className="fas fa-arrow-right" />{" "}
                    <span>Explore more</span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <span className="big-text light-opacity">Let’s Work Together</span>
      </section>
      {/* Work With Area end */}
      {/* footer area start */}
    </Layout>
  );
};

export default ProjectList;