import { useState } from "react";
import { Accordion } from "react-bootstrap";
import PageBanner from "../components/PageBanner";
import YgencyAccordion from "../components/YgencyAccordion";
import Layout from "../layouts/Layout";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../config/firebase";

const Faqs = () => {
  const accordionData = [
    {
      id: 0,
      title: "What services do you offer ?",
      des: "We provide a range of services, including web development, mobile app development (iOS & Android), UI/UX design, e-commerce solutions, API integrations, cloud solutions, and ongoing maintenance and support.",
    },
    {
      id: 1,
      title: "How do you determine the cost of a project ?",
      des: "Our pricing is based on several factors, including the project scope, complexity, design requirements, development timeline, and any additional features or integrations. We offer both fixed-price and hourly rate options.",
    },
    {
      id: 2,
      title: "How long does it take to develop a website or mobile app ?",
      des: (
        <ul>
          <li>
            <strong>Basic Website:</strong> 2-6 weeks
          </li>
          <li>
            <strong>Custom Web Application:</strong> 3-6 months
          </li>
          <li>
            <strong>Basic Mobile App:</strong> 8-12 weeks
          </li>
          <li>
            <strong>Complex Mobile App:</strong> 4-9 months
          </li>
        </ul>
      ),
    },
    {
      id: 3,
      title: "What technologies do you use ?",
      des: (
        <div>
          <strong>Web Development:</strong> HTML, CSS, JavaScript (React,
          Angular, Vue.js), PHP, Node.js, Python, Ruby on Rails.
          <br />
          <strong>Mobile Development:</strong> React Native, Flutter, Swift,
          Kotlin.
          <br />
          <strong>Backend:</strong> Laravel, Django, Express.js, Spring Boot.
          <br />
          <strong>Databases:</strong> MySQL, PostgreSQL, MongoDB, Firebase.
        </div>
      ),
    },
    {
      id: 4,
      title: "What is your development process ?",
      des: (
        <ol>
          <li>
            <strong>Discovery & Planning:</strong> Understanding your business
            needs.
          </li>
          <li>
            <strong>Design:</strong> Creating wireframes and prototypes.
          </li>
          <li>
            <strong>Development:</strong> Coding, integrating APIs, and building
            features.
          </li>
          <li>
            <strong>Testing:</strong> QA and bug fixing.
          </li>
          <li>
            <strong>Deployment:</strong> Launching your project live.
          </li>
          <li>
            <strong>Maintenance:</strong> Ongoing support and updates.
          </li>
        </ol>
      ),
    },
    {
      id: 5,
      title: "Do you offer ongoing support and maintenance ?",
      des: "Yes! We offer various maintenance plans, from basic support to premium packages that include continuous development, updates, and technical support.",
    },
    {
      id: 6,
      title: "Can you work with my existing team ?",
      des: "Absolutely! We can collaborate with your in-house team to complement their skills or handle specific parts of the project.",
    },
    {
      id: 7,
      title: "Can you create custom designs for my website or app ?",
      des: "Yes, we offer bespoke UI/UX design services to ensure your website or app stands out while maintaining excellent user experience and brand consistency.",
    },
    {
      id: 8,
      title: "Do you handle third-party API integrations ?",
      des: "Yes, we can integrate third-party APIs, such as payment gateways, social media platforms, CRM systems, and more, to enhance the functionality of your web or mobile app.",
    },
    {
      id: 9,
      title: "How do you ensure the security of my website or app ?",
      des: "We implement industry best practices, including data encryption, secure coding standards, regular security audits, and compliance with GDPR, HIPAA, or other relevant regulations.",
    },
    {
      id: 10,
      title: "Will my project be scalable in the future ?",
      des: "Yes, we design and develop with scalability in mind, allowing your website or app to grow seamlessly as your business expands.",
    },
    {
      id: 11,
      title: "Who owns the source code after the project is complete ?",
      des: "You will have full ownership of the source code and all project deliverables once the project is completed and all payments are made.",
    },
    {
      id: 12,
      title:
        "What happens if I want to make changes after the project is launched ?",
      des: "We're happy to assist with post-launch changes. You can choose from our maintenance plans or request changes on an hourly basis.",
    },
    {
      id: 13,
      title: "How do we communicate throughout the project ?",
      des: "We offer regular updates via email, Zoom, Slack, or any preferred communication platform. You’ll also have a dedicated project manager to keep everything on track.",
    },
  ];
  const ClientCollectionRef = collection(db, "Client");

  const [active, setActive] = useState("collapse0");
    const [formValues, setFormValues] = useState({
      first_name: "",
      email: "",
      phone_number: "",
      requirement: "",
      message: "",
    });

    
  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

   // Handle form submission
    const handleSubmit = async(e) => {
      e.preventDefault();
  
      try {
        await addDoc(ClientCollectionRef, {
          first_name: formValues.first_name,
          email: formValues.email,
          phone_number: formValues.phone_number,
          requirement:formValues.requirement,
          message:formValues.message
        })
        alert('Thanks for choosing us!')
        setFormValues({
          first_name: "",
          email: "",
          phone_number: "",
          requirement: "",
          message: "",
        })
    } catch (e) {
        console.log('e', e)
    }
    };

  return (
    <Layout header={2} footer={2}>
      {/* Page Banner Start */}
      {/* <PageBanner pageName={"Faqs"} /> */}
      {/* Page Banner End */}
      {/* FAQ's Area start */}
      <section
        className="page-banner pt-210 rpt-150 pb-30 rpb-50 rel z-1"
        style={{ backgroundImage: "url(assets/images/hero/hero-two-bg.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="hero-title mb-10 rmb-50 wow fadeInUp delay-0-2s">We're here to answer all your questions.</h1>
              <p className="faq-para1">Our FAQ section is designed to help you better understand our web and app development services, processes, and how we work with clients. Whether you're considering starting a project with us or are already a client, you'll find answers to common questions about our offerings, pricing, development timelines, technologies, and more.</p>
            </div>
            <div className="col-lg-4">
              <div className="faq-image-part rmb-55 wow fadeInLeft delay-0-2s">
                <img src="assets/images/faqs/faq-two.jpg" alt="FAQs" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faq-area pt-50 rpt-50 pb-130 rpb-75 rel z-1">
        <div className="container">
          <div className="row align-items-center justify-content-center gap-100">
            {/* <div className="col-lg-5">
              <div className="faq-iamge-part rmb-55 wow fadeInLeft delay-0-2s">
                <img src="assets/images/faqs/faq-two.jpg" alt="FAQs" />
              </div>
            </div> */}
            <div className="col-lg-10">
              <div className="faq-content-part wow fadeInRight delay-0-2s">
                <div className="section-title mb-60">
                  <span className="sub-title mb-15">Asked Questions</span>
                  <h2>Frequently Asked Questions</h2>
                </div>
                <Accordion
                  defaultActiveKey="collapse0"
                  className="accordion"
                  id="faq-accordion-two"
                >
                  {accordionData.map((data, i) => (
                    <YgencyAccordion
                      title={data.title}
                      key={data.id}
                      des={data.des}
                      event={`collapse${i}`}
                      onClick={() =>
                        setActive(
                          active == `collapse${data.id}` ? "" : `collapse${data.id}`
                        )
                      }
                      active={active}
                    />
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-shapes">
          <img
            className="shape left"
            src="assets/images/shapes/ellipse-left.png"
            alt="Shape"
          />
          <img
            className="shape right"
            src="assets/images/shapes/ellipse-right.png"
            alt="Shape"
          />
        </div>
      </section>
      {/* FAQ's Area end */}
      {/* FAQ's Area start */}
      {/* <section className="faq-area pb-105 rpb-75 rel z-1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 order-lg-2">
              <div className="faq-iamge-part text-lg-end rmb-55 wow fadeInLeft delay-0-2s">
                <img src="assets/images/faqs/faq-three.png" alt="FAQs" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="faq-content-part wow fadeInRight delay-0-2s">
                <div className="section-title mb-60">
                  <span className="sub-title mb-15">Asked Questions</span>
                  <h2>Frequently Asked Questions</h2>
                </div>
                <Accordion
                  defaultActiveKey="collapse0"
                  className="accordion"
                  id="faq-accordion-two"
                >
                  {accordionData.map((data, i) => (
                    <YgencyAccordion
                      title={data.title}
                      key={data.id}
                      event={`collapse${i}`}
                      onClick={() =>
                        setActive(
                          active == `collapse${i}` ? "" : `collapse${i}`
                        )
                      }
                      active={active}
                    />
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* FAQ's Area end */}
      {/* Headline area start */}
      <div className="headline-area bgc-primary pt-80 pb-65">
        <div className="container-fluid">
          <div className="headline-wrap marquee">
            <span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Design &amp; Branding</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Web Development</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Mobile Apps</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Design &amp; Branding</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Web Development</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Mobile Apps</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Design &amp; Branding</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Web Development</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Mobile Apps</b>
              </span>
            </span>
          </div>
        </div>
      </div>
      {/* Headline Area end */}
      {/* Contact Form Area start */}
      <section className="contact-area py-130 rpy-100 rel z-1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title mb-50 text-center wow fadeInUp delay-0-2s">
                <span className="sub-title mb-15">Work Together</span>
                <h2>Let’s Work Together For Your Projects</h2>
              </div>
            </div>
            <div className="col-xl-10 col-lg-11">
              <form
                id="teamForm"
                className="form-style-one z-1 rel wow fadeInUp delay-0-3s"
                action="#"
                name="teamForm"
                method="post"
                onSubmit={handleSubmit}
              >
                <div className="row gap-60 pt-15">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">
                        <i className="far fa-user" />
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        className="form-control"
                        placeholder="Full Name"
                        required
                        value={formValues.first_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="email">
                        <i className="far fa-envelope" />
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email Address"
                        required
                        value={formValues.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="phone">
                        <i className="far fa-phone" />
                      </label>
                      <input
                        type="text"
                        id="phone_number"
                        name="phone_number"
                        className="form-control"
                        placeholder="Phone"
                        value={formValues.phone_number}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-50">
                    <div className="form-group">
                      <select name="requirement" id="requirement" value={formValues.requirement} onChange={handleChange}>
                        <option value="default" selected>
                          Subject
                        </option>
                        <option value="Application Design">Application Design</option>
                        <option value="Website Design">Website Design</option>
                        <option value="Application Development">Application Development</option>
                        <option value="Website Development">Website Development</option>
                        <option value="Website Development">Website Development</option>
                        <option value="SEO">Full Stack Development</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="message">
                        <i className="far fa-pencil" />
                      </label>
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows={4}
                        placeholder="Message"
                        required
                        value={formValues.message}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group pt-5 mb-0">
                      <button
                        type="submit"
                        className="theme-btn style-two w-100"
                      >
                        Send Message us <i className="far fa-arrow-right" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Form Area end */}
      {/* footer area start */}
    </Layout>
  );
};

export default Faqs;