import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppRouter from './router/AppRouter';
import './styles/globals.css';

function App() {
  return (
    <div>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
