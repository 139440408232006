// import { Link } from "react-router-dom";
// import { Accordion } from "react-bootstrap";
// import Menu from "./Menu";

// const Nav = ({ singleMenu }) => {
//   return (
//     <nav className="main-menu navbar-expand-lg">
//       <Accordion>
//         <div className="navbar-header py-10">
//           <div className="mobile-logo">
//             <Link to="/">
//               <a>
//                 <img
//                   src="assets/images/logos/logo.png"
//                   alt="Logo"
//                   title="Logo"
//                 />
//               </a>
//             </Link>
//           </div>
//           {/* Toggle Button */}
//           {/* <Accordion.Toggle
//             as={"button"}
//             className="navbar-toggle"
//             eventKey="navbar-collapse"
//           >
//             <span className="icon-bar" />
//             <span className="icon-bar" />
//             <span className="icon-bar" />
//           </Accordion.Toggle> */}
//           <Accordion.Toggle
//             as={"button"}
//             className="navbar-toggle"
//             eventKey="navbar-collapse"
//             aria-controls="navbar-collapse"
//           >
//             <span className="icon-bar" />
//             <span className="icon-bar" />
//             <span className="icon-bar" />
//           </Accordion.Toggle>
//         </div>
//         <Accordion.Collapse
//           eventKey="navbar-collapse"
//           className="navbar-collapse clearfix"
//         >
//           <Menu />
//         </Accordion.Collapse>
//       </Accordion>
//     </nav>
//   );
// };

// export default Nav;

import { Link } from "react-router-dom";
import { useState } from "react";
import Menu from "./Menu";

const Nav = ({ singleMenu }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control menu visibility

  // Toggle the mobile menu
  const toggleMenu = () => {
    console.log("Toggling menu", !isMenuOpen ? "Open" : "Close"); // Log the state change
    setIsMenuOpen((prev) => !prev); // This will toggle the menu open/close
  };


  // Close the menu when a menu item is clicked
  const closeMenu = () => {
    console.log("Closing menu"); // Log when the menu closes
    setIsMenuOpen(false); // Close the menu when a menu item is clicked
  };

  return (
    <nav className="main-menu navbar-expand-lg">
      <div className="navbar-header py-10">
        <div className="mobile-logo">
          <Link to="/">
            <a>
              <img
                src="assets/images/logos/logo.png"
                alt="Logo"
                title="Logo"
              />
            </a>
          </Link>
        </div>

        {/* Toggle Button (Hamburger) */}
        <button
          className="navbar-toggle"
          onClick={toggleMenu} // Toggle menu visibility on button click
          aria-label="Toggle navigation"
        >
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
      </div>

      {/* Mobile Menu */}
      {/* When isMenuOpen is true, show the menu; otherwise, hide it */}
      <div
        className={`navbar-collapse clearfix ${isMenuOpen ? "show" : ""}`}
      >
        <Menu closeMenu={closeMenu} />
      </div>
    </nav>
  );
};

export default Nav;