import DefaultFooter from "./DefaultFooter";

const Footer = ({ footer, dark }) => {
  switch (footer) {
    case 1:
      return <DefaultFooter dark={dark} />;
    default:
      return <DefaultFooter dark={dark} />;
  }
};

export default Footer;