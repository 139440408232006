import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About.js";
import Contact from '../pages/Contact';
import Faqs from '../pages/Faqs';
import NotFound404 from "../pages/NotFound404";
import Services from "../pages/Services.js";
import ServiceDetails from "../pages/ServiceDetails";
import Blog from "../pages/Blog.js";
import BlogDetails from "../pages/BlogDetails.js";
import Pricing from "../pages/Pricing.js";
import Team from "../pages/Team.js";
import TeamDetails from "../pages/TeamDetails.js";
import Projects from "../pages/Projects.js";
import ProjectDetails from "../pages/ProjectDetails.js";
import ProjectList from "../pages/ProjectList.js";
// import Layout from "../layouts/Layout"; 

const AppRouter = () => {
  return (
    // <Layout>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/faqs" element={<Faqs />} />
      <Route path="/service-details" element={<ServiceDetails />} />    
      <Route path="/team" element={<Team />} />
      <Route path="/team-details" element={<TeamDetails />} />
      <Route path="/projects" element={<Projects/>} />
      <Route path="/project-details" element={<ProjectDetails />} />
      <Route path="/project-list" element={<ProjectList />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog-details" element={<BlogDetails />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/services" element={<Services />} />
      <Route path="*" element={<NotFound404 />} />
    </Routes>
    // </Layout>
  );
};

export default AppRouter;