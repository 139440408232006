import React, { useState } from "react";
import PageBanner from "../components/PageBanner";
import Layout from "../layouts/Layout";
import { db } from "../config/firebase";
import { collection, addDoc } from "firebase/firestore";

const Contact = () => {
  // State to hold form values
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    requirement: "",
    message: "",
  });
  const ClientCollectionRef = collection(db, "Client");

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Handle radio button change
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async(e) => {
    e.preventDefault();

    try {
      await addDoc(ClientCollectionRef, {
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        email: formValues.email,
        phone_number: formValues.phone_number,
        requirement:formValues.requirement,
        message:formValues.message
      })
      alert('Thanks for choosing us!')
      setFormValues({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        requirement: "",
        message: "",
      })
  } catch (e) {
      console.log('e', e)
  }
  };

  return (
    <Layout header={2} footer={2}>
      {/* Page Banner Start */}
      {/* <PageBanner pageName={"Contact"} /> */}
      <section
        className="page-banner pt-210 rpt-150 pb-30 rpb-50 rel z-1"
        style={{ backgroundImage: "url(assets/images/hero/hero-two-bg.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h1 className="hero-title mb-10 rmb-50 wow fadeInUp delay-0-2s">
                Ready to Work Your Next Projects
              </h1>
              <p className="cnct-para1">
                Get in touch with our team at Ivtics for personalized support
                and solutions. We're here to help your business thrive.
              </p>
              <p>
                Just fill out the form, and our team will be in touch shortly.
              </p>
            </div>
            <div className="col-lg-5">
              <div className="contact-image-part rmb-55 wow fadeInLeft delay-0-2s rounded">
                <img src="assets/images/hero/hero-two.jpg" alt="Contact" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Page Banner End */}
      {/* Contact Form Area start */}
      <section className="contact-page-area py-50 rpy-50 rel z-1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-11">
              <div className="contact-form-card card shadow p-4">
                <div className="section-title mb-35 text-center">
                  <h3>Drop Us a Message</h3>
                  <div className="underline-dot-circle">
                    <span></span>
                  </div>
                </div>
                <form
                  id="contactForm"
                  className="contactForm"
                  name="contactForm"
                  method="post"
                  onSubmit={handleSubmit}
                >
                  <div className="row gap-30 ps-5 pe-5">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          className="form-control"
                          placeholder="First Name"
                          value={formValues.first_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          type="text"
                          id="last_name"
                          name="last_name"
                          className="form-control"
                          placeholder="Last Name"
                          value={formValues.last_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="email">Email Address</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email Address"
                          value={formValues.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="phone_number">Your Phone Number</label>
                        <input
                          type="text"
                          id="phone_number"
                          name="phone_number"
                          className="form-control"
                          placeholder="Your Phone Number"
                          value={formValues.phone_number}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Select Your Requirement</label>
                        <div className="cnct-radio-options">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="requirement"
                              id="app_development"
                              value="App Development"
                              checked={
                                formValues.requirement === "App Development"
                              }
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="app_development"
                            >
                              App Development
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="requirement"
                              id="web_development"
                              value="Web Development"
                              checked={
                                formValues.requirement === "Web Development"
                              }
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="web_development"
                            >
                              Web Development
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="requirement"
                              id="ideation_strategy"
                              value="Ideation & Strategy"
                              checked={
                                formValues.requirement === "Ideation & Strategy"
                              }
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="ideation_strategy"
                            >
                              Ideation & Strategy
                            </label>
                          </div>
                        </div>
                        <div className="cnct-radio-options">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="requirement"
                              id="product_design"
                              value="Product Design"
                              checked={
                                formValues.requirement === "Product Design"
                              }
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="product_design"
                            >
                              Product Design
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="requirement"
                              id="cloud_services"
                              value="Cloud Services"
                              checked={
                                formValues.requirement === "Cloud Services"
                              }
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="cloud_services"
                            >
                              Cloud Services
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="requirement"
                              id="other_requirement"
                              value="Other Requirement"
                              checked={
                                formValues.requirement === "Other Requirement"
                              }
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="other_requirement"
                            >
                              Other Requirement
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="message">
                          Tell us more about your request
                        </label>
                        <textarea
                          name="message"
                          id="message"
                          className="form-control"
                          rows="8"
                          placeholder="Describe your request"
                          value={formValues.message}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12 text-center">
                      <button type="submit" className="btn submt-btn">
                        Send Request
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Form Area end */}
      {/* How to Proceed Section */}
      <section className="how-to-proceed mt-20 pb-40">
        <div className="container">
          <div className="row justify-content-center">
            <div className="section-title text-center mb-5">
              <h3>How to Proceed</h3>
              <div className="underline-dot-circle">
                <span></span>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-3 step-box">
                <div className="step-number text-center">Step 1</div>
                <div className="step-description pb-10">
                  Please share your business requirements. Our process for
                  corporate privacy is transparent and open to signing NDA.
                </div>
              </div>
              <div className="col-md-3 step-box">
                <div className="step-number text-center">Step 2</div>
                <div className="step-description pb-10">
                  Our consultant will closely work with you for an in-depth
                  discussion of your business and will decide on the optimal
                  business solution.
                </div>
              </div>
              <div className="col-md-3 step-box">
                <div className="step-number text-center">Step 3</div>
                <div className="step-description pb-10">
                  We will submit our technical and commercial proposal upon
                  completion of the analysis, followed by a proposal walkthrough
                  for clarification or modification of the proposal.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Location Map Area Start */}
      {/* <div className="contact-page-map mb-120 rpb-90 wow fadeInUp delay-0-2s">
        <div className="container-fluid">
          <div className="our-location">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m12!1m10!1m3!1d142190.2862584524!2d-74.01298319978558!3d40.721725351435126!2m1!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sbd!4v1663473911885!5m2!1sen!2sbd"
              style={{ border: 0, width: "100%" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div> */}
      {/* Location Map Area End */}
      {/* footer area start */}
    </Layout>
  );
};

export default Contact;