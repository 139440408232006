import React from "react";
import Layout from "../layouts/Layout";
import { sliderProps } from "../sliderProps";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

const Counter = React.lazy(() => import("../components/Counter"));

const About = () => {
  return (
    <Layout header={2} footer={2}>
      {/* Page Banner Section Start */}
      <section
        className="page-banner pt-210 rpt-150 pb-45 rpb-50 rel z-1"
        style={{ backgroundImage: "url(assets/images/hero/hero-two-bg.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="hero-title mb-10 rmb-5 wow fadeInUp delay-0-2s">
                Welcome to daxonix infotech
              </h1>
              <p>
                At daxonix infotech, we deliver cutting-edge IT solutions
                designed to drive innovation and efficiency for your business.
                From IoT and AI to Cloud and App Development, our tailored
                services help you harness the power of technology to achieve
                sustainable growth and success. Let's shape the future of
                digital transformation together.
              </p>
              <button className="btn about-cnct-btn">Contact Us</button>
            </div>
            <div className="col-lg-4">
              <div className="about-image-part rmt-25 rmb-55 wow fadeInLeft delay-0-2s">
                <img src="assets/images/banner/banner-bg.jpg" alt="About Us" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Video Area end */}
      {/* Who We Are start */}
      {/* <section className="who-we-are-area pt-100 rpt-80 pb-75 rpb-45 rel z-1">
        <div className="container container-1290">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-title text-center mb-70 wow fadeInUp delay-0-2s">
                <span className="sub-title mb-15">Who We Are</span>
                <h2>We are Professional and Experience Team </h2>
              </div>
            </div>
          </div>
          <div className="row gap-90">
            <div className="col-lg-4 col-md-6">
              <div className="why-choose-item style-two wow fadeInUp delay-0-2s">
                <div className="why-choose-header">
                  <i className="flaticon-creativity" />
                  <h5>Customize your site</h5>
                </div>
                <p>
                  At vero eos et accusamus iusto dignissim ducimuse blanditiis
                  praesentiue
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="why-choose-item style-two wow fadeInUp delay-0-4s">
                <div className="why-choose-header">
                  <i className="flaticon-mobile-banking" />
                  <h5>Edit your mobile view</h5>
                </div>
                <p>
                  Ut enim ad minima veniam, quis nostrum exercitationem ullam
                  corporis suscipit
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="why-choose-item style-two wow fadeInUp delay-0-6s">
                <div className="why-choose-header">
                  <i className="flaticon-optimization-1" />
                  <h5>Optimize search engines</h5>
                </div>
                <p>
                  Quis autem vel eum iure reprehenderi qui ea voluptate velit
                  esse quam nihile
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="who-we-are-area pt-50 rpt-40 rel z-1">
        <div className="container container-1290">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h2 className="sub-title text-center">Who We Are</h2>
              <div className="underline-dot-circle">
                <span></span>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="section-title mb-70 rmb-20 wow fadeInUp delay-0-2s">
                <p s>
                  Daxonix Infotech is based in Surat, Gujarat. A city that is
                  perfect for software nearshoring because of its location and
                  time zone.
                </p>
                <p>
                  We are a passionate team of developers, designers, and
                  strategists dedicated to transforming ideas into innovative
                  digital solutions. Our expertise lies in creating high-quality
                  websites and mobile applications tailored to meet the unique
                  needs of businesses across various industries.
                </p>
                <p>
                  With a commitment to excellence, we combine cutting-edge
                  technology with creative design to deliver user-friendly,
                  scalable, and secure solutions. Whether you're a startup, a
                  growing business, or an established enterprise, we partner
                  with you to bring your vision to life and help you thrive in
                  the digital world.
                </p>
                <p>
                  At our core, we believe in collaboration, transparency, and
                  delivering exceptional results that exceed expectations. Let's
                  build something great together!
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4">
              <div className="about-image2-part rmb-55 wow fadeInLeft delay-0-2s">
                <img src="assets/images/about/about.png" alt="Who we are" />
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* Who We Are end */}
      {/* Headline area start */}
      <div className="headline-area bgc-primary pt-80 pb-65">
        <div className="container-fluid">
          <div className="headline-wrap marquee">
            <span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Design &amp; Branding</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Web Development</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Mobile Apps</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Design &amp; Branding</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Web Development</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Mobile Apps</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Design &amp; Branding</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Web Development</b>
              </span>
              <span className="marquee-item">
                <i className="fas fa-star-of-life" />
                <b>Mobile Apps</b>
              </span>
            </span>
          </div>
        </div>
      </div>
      {/* Headline Area end */}
      {/* Team Area start */}
      {/* <section className="team-area pt-130 rpt-100">
        <div className="container-fluid">
          <div className="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 justify-content-center">
            <div className="col">
              <div className="team-member wow fadeInUp delay-0-2s">
                <div className="image">
                  <img src="assets/images/team/member6.jpg" alt="Team Member" />
                </div>
                <div className="content">
                  <h4>Patrick V. Schroeder</h4>
                  <span>UI/UX Designer</span>
                  <Link to="/team-details">
                    <a className="read-more">
                      <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
                <div className="btn-social">
                  <Link to="/team-details">
                    <a className="read-more">
                      <span>View Details</span>{" "}
                      <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                  <div className="social-style-two">
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram" />
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="team-member wow fadeInUp delay-0-3s">
                <div className="image">
                  <img src="assets/images/team/member7.jpg" alt="Team Member" />
                </div>
                <div className="content">
                  <h4>Michael A. Braun</h4>
                  <span>UI/UX Designer</span>
                  <Link to="/team-details">
                    <a className="read-more">
                      <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
                <div className="btn-social">
                  <Link to="/team-details">
                    <a className="read-more">
                      <span>View Details</span>{" "}
                      <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                  <div className="social-style-two">
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram" />
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="team-member wow fadeInUp delay-0-4s">
                <div className="image">
                  <img src="assets/images/team/member8.jpg" alt="Team Member" />
                </div>
                <div className="content">
                  <h4>James V. Decastro</h4>
                  <span>Senior Marketer</span>
                  <Link to="/team-details">
                    <a className="read-more">
                      <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
                <div className="btn-social">
                  <Link to="/team-details">
                    <a className="read-more">
                      <span>View Details</span>{" "}
                      <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                  <div className="social-style-two">
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram" />
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="team-member wow fadeInUp delay-0-5s">
                <div className="image">
                  <img src="assets/images/team/member9.jpg" alt="Team Member" />
                </div>
                <div className="content">
                  <h4>Troy V. Richardson</h4>
                  <span>Web Designer</span>
                  <Link to="/team-details">
                    <a className="read-more">
                      <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
                <div className="btn-social">
                  <Link to="/team-details">
                    <a className="read-more">
                      <span>View Details</span>{" "}
                      <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                  <div className="social-style-two">
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram" />
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="team-member wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/team/member10.jpg"
                    alt="Team Member"
                  />
                </div>
                <div className="content">
                  <h4>Michael A. Braun</h4>
                  <span>Apps Designer</span>
                  <Link legacyBehavto="/team-details">
                    <a className="read-more">
                      <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
                <div className="btn-social">
                  <Link legacyBehavto="/team-details">
                    <a className="read-more">
                      <span>View Details</span>{" "}
                      <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                  <div className="social-style-two">
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram" />
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Team Area end */}
      {/* Statistics Area start */}
      <div
        className="statistics-area pt-100 rpt-70 rel z-1"
        style={{ backgroundImage: "url(assets/images/hero/hero-two-bg.png)" }}
      >
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-2 col-lg-3 col-6">
              <div className="counter-item counter-text-wrap wow fadeInRight delay-0-2s">
                <i className="fal fa-check-circle" />
                <Counter end={7} extraClass={"plus"} />
                <span className="counter-title">Years Of Experience</span>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-6">
              <div className="counter-item counter-text-wrap wow fadeInRight delay-0-3s">
                <i className="fal fa-check-circle" />
                <Counter end={5} extraClass={"plus"} />
                <span className="counter-title">Project's Complete</span>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-6">
              <div className="counter-item counter-text-wrap wow fadeInRight delay-0-4s">
                <i className="fal fa-check-circle" />
                <Counter end={98} extraClass={"percent"} />
                <span className="counter-title">Client Satisfaction</span>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-6">
              <div className="counter-item counter-text-wrap wow fadeInRight delay-0-5s">
                <i className="fal fa-check-circle" />
                <Counter end={5} extraClass={"plus"} />
                <span className="counter-title">Countries Served</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Statistics Area end */}
      {/* Core Values Cards */}
      <section className="core-values-section pt-50 rpt-40 pb-40 rpb-20 rel z-1">
        <div className="container container-1290">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h2 className="sub-title mb-15 text-center">Our Core Values</h2>
              <div className="underline-dot-circle">
                <span></span>
              </div>
              <p className="text-center">
                Top brands trust our expertise because we offer innovative,
                cost-effective solutions, backed by skilled teams and a
                commitment to delivering quality, tailored results that drive
                business success.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-12">
              <div className="core-value-card">
                <img
                  src="assets/images/coreValues/core_values_9.png"
                  alt="Skilled and Trained Teams"
                />
                <h3>Skilled and Trained Teams</h3>
                <p>Experts with deep industry knowledge and experience.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12">
              <div className="core-value-card">
                <img
                  src="assets/images/coreValues/core_values_10.png"
                  alt="Cost-effective Services"
                />
                <h3>Cost-effective Services</h3>
                <p>Affordable solutions without compromising on quality.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12">
              <div className="core-value-card">
                <img
                   src="assets/images/coreValues/core_values_5.png"
                  alt="Cutting-Edge Technology"
                />
                <h3>Cutting-Edge Technology</h3>
                <p>
                  Utilizing the latest tools and techniques for optimal results.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12">
              <div className="core-value-card">
                <img
                  src="assets/images/coreValues/core_values_3.png"
                  alt="Proven Track Record"
                />
                <h3>Proven Track Record</h3>
                <p>
                  Successfully delivering projects for top brands across
                  industries.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12">
              <div className="core-value-card">
                <img
                  src="assets/images/coreValues/core_values_6.png"
                  alt="Tailored Solutions"
                />
                <h3>Tailored Solutions</h3>
                <p>Custom solutions to meet unique business needs.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12">
              <div className="core-value-card">
                <img
                  src="assets/images/coreValues/core_values_1.png"
                  alt="Data-Driven Decision Making"
                />
                <h3>Data-Driven Decision Making</h3>
                <p>Using analytics and insights to guide solutions.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12">
              <div className="core-value-card">
                <img
                   src="assets/images/coreValues/core_values_7.png"
                  alt="Strong Client Collaboration"
                />
                <h3>Strong Client Collaboration</h3>
                <p>
                  Close partnership with clients to ensure alignment with goals.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12">
              <div className="core-value-card">
                <img
                  src="assets/images/coreValues/core_values_4.png"
                  alt="Commitment to Innovation"
                />
                <h3>Commitment to Innovation</h3>
                <p>Continuously improving with the latest tech trends.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="discuss-project-section">
        <div class="container">
          <div class="discuss-box">
            <h2 class="section-heading-one">LET'S DISCUSS YOUR PROJECT</h2>
            <div class="underline-dot-circle">
              <span></span>
            </div>
            <h2 className="section-heading-two">Ready to get started!</h2>
            <p class="description">
              Have a word with our expert consultants about your next project to
              get suggestive guidance & proposal.
            </p>
            <a
                href="https://calendly.com/daxonixinfotech"
                target="_blank"
              >
            <a class="btn schedule-call-btn">Schedule Call</a>
            </a>
          </div>
        </div>
      </section>
      {/* Testimonial Area start */}
      {/* <section className="testimonial-section pt-70 rpt-30">
        <div className="container container-1210">
          <div className="row">
            <div className="col-lg-6">
              <div className="image-border-shape rmb-20 wow fadeInRight delay-0-2s">
                <img
                  src="assets/images/testimonials/testimonials-four.jpg"
                  alt="Testimonial Left Image"
                />
                <div className="bottom-border" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="testimonial-one-right-part wow fadeInLeft delay-0-2s">
                <Swiper
                  {...sliderProps.testimonialsActiveSwiper}
                  className="testimonials-active"
                >
                  <SwiperSlide className="testimonial-item">
                    <div className="author-speech">
                      <p>
                        <span className="quote">“</span> On the other hand we
                        denounce with see righteous indignation and dislike men
                        who are beguiled and demoralized by the charms offset
                        pleasure moments line desire that they cannot foresee
                        pain and trouble that are bound ensue and equal blame
                        belongs their duty{" "}
                        <span className="quote right-quote">“</span>
                      </p>
                    </div>
                    <div className="testimonial-footer">
                      <div className="testimonial-author">
                        <div className="author-image">
                          <img
                            src="assets/images/testimonials/author1.png"
                            alt="Author Image"
                          />
                        </div>
                        <div className="author-info">
                          <h4>James N. Johnson</h4>
                          <span className="designation">CEO &amp; Founder</span>
                        </div>
                      </div>
                      <div className="ratting style-two">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star-half-alt" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="testimonial-item">
                    <div className="author-speech">
                      <p>
                        <span className="quote">“</span> On the other hand we
                        denounce with see righteous indignation and dislike men
                        who are beguiled and demoralized by the charms offset
                        pleasure moments line desire that they cannot foresee
                        pain and trouble that are bound ensue and equal blame
                        belongs their duty{" "}
                        <span className="quote right-quote">“</span>
                      </p>
                    </div>
                    <div className="testimonial-footer">
                      <div className="testimonial-author">
                        <div className="author-image">
                          <img
                            src="assets/images/testimonials/author1.png"
                            alt="Author Image"
                          />
                        </div>
                        <div className="author-info">
                          <h4>James N. Johnson</h4>
                          <span className="designation">CEO &amp; Founder</span>
                        </div>
                      </div>
                      <div className="ratting style-two">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star-half-alt" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="testimonial-item">
                    <div className="author-speech">
                      <p>
                        <span className="quote">“</span> On the other hand we
                        denounce with see righteous indignation and dislike men
                        who are beguiled and demoralized by the charms offset
                        pleasure moments line desire that they cannot foresee
                        pain and trouble that are bound ensue and equal blame
                        belongs their duty{" "}
                        <span className="quote right-quote">“</span>
                      </p>
                    </div>
                    <div className="testimonial-footer">
                      <div className="testimonial-author">
                        <div className="author-image">
                          <img
                            src="assets/images/testimonials/author1.png"
                            alt="Author Image"
                          />
                        </div>
                        <div className="author-info">
                          <h4>James N. Johnson</h4>
                          <span className="designation">CEO &amp; Founder</span>
                        </div>
                      </div>
                      <div className="ratting style-two">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star-half-alt" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="testimonial-item">
                    <div className="author-speech">
                      <p>
                        <span className="quote">“</span> On the other hand we
                        denounce with see righteous indignation and dislike men
                        who are beguiled and demoralized by the charms offset
                        pleasure moments line desire that they cannot foresee
                        pain and trouble that are bound ensue and equal blame
                        belongs their duty{" "}
                        <span className="quote right-quote">“</span>
                      </p>
                    </div>
                    <div className="testimonial-footer">
                      <div className="testimonial-author">
                        <div className="author-image">
                          <img
                            src="assets/images/testimonials/author1.png"
                            alt="Author Image"
                          />
                        </div>
                        <div className="author-info">
                          <h4>James N. Johnson</h4>
                          <span className="designation">CEO &amp; Founder</span>
                        </div>
                      </div>
                      <div className="ratting style-two">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star-half-alt" />
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="testimonial-controls mt-75 rmt-40">
                  <button className="testimonial-prev slick-arrow">
                    <i className="far fa-chevron-left" />
                  </button>
                  <div className="testimonial-dots">
                    <div className="slick-dots"></div>
                  </div>
                  <button className="testimonial-next slick-arrow">
                    <i className="far fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Testimonial Area end */}
      {/* Client Logo Two start */}
      {/* <section className="client-logo-area pt-130 rpt-100 pb-100 rpb-70">
        <div className="container">
          <div className="section-title text-center mb-60">
            <h4>We Have 1520+Global Clients</h4>
          </div>
          <div className="client-logo-wrap">
            <Link to="/contact">
              <a className="client-logo-item wow fadeInUp delay-0-2s">
                <img
                  src="assets/images/client-logos/client-logo1.png"
                  alt="Client Logo"
                />
              </a>
            </Link>
            <Link to="/contact">
              <a className="client-logo-item wow fadeInUp delay-0-3s">
                <img
                  src="assets/images/client-logos/client-logo2.png"
                  alt="Client Logo"
                />
              </a>
            </Link>
            <Link to="/contact">
              <a className="client-logo-item wow fadeInUp delay-0-4s">
                <img
                  src="assets/images/client-logos/client-logo3.png"
                  alt="Client Logo"
                />
              </a>
            </Link>
            <Link to="/contact">
              <a className="client-logo-item wow fadeInUp delay-0-5s">
                <img
                  src="assets/images/client-logos/client-logo4.png"
                  alt="Client Logo"
                />
              </a>
            </Link>
            <Link to="/contact">
              <a className="client-logo-item wow fadeInUp delay-0-6s">
                <img
                  src="assets/images/client-logos/client-logo5.png"
                  alt="Client Logo"
                />
              </a>
            </Link>
            <Link to="/contact">
              <a className="client-logo-item wow fadeInUp delay-0-7s">
                <img
                  src="assets/images/client-logos/client-logo6.png"
                  alt="Client Logo"
                />
              </a>
            </Link>
          </div>
        </div>
      </section> */}
    </Layout>
  );
};

export default About;
